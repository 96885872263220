import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { ReactComponent as Logo } from '../alcofunk.svg'
import { Container } from '@material-ui/core'

export default function Home() {
    return (
        <Box mt={8} mx="auto">
            <Typography variant="h6">Home</Typography>
        </Box>
    )
}
