import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            // main: '#ffa726',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            // main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
        error: {
            main: red.A400,
        },
        primary: {
            main: '#ff9800',
        },
        secondary: {
            main: '#ffc400',
        },
        // background: {
        //     default: '#282c34',
        // },
    },
})

export default theme
