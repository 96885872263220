import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Alco from './Alco/Alco'

import Home from './Home'
const Pages = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/alco-app">
                <Alco />
                {/* <div> Alcofunk X BEлодрочЕRы </div> */}
            </Route>
        </Switch>
    )
}

export default Pages
