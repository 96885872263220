import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Box from '@material-ui/core/Box'
import { Link as RouterLink } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import Link from '@material-ui/core/Link'
import NavDrawer from './Drawer'

import { ReactComponent as Logo } from '../../alcofunk.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        height: theme.spacing(4),
    },
}))

const NavBar = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [state, setState] = React.useState({
        open: false,
    })

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setState({ ...state, open })
    }

    const isMobile = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <div>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {isMobile && (
                        <React.Fragment>
                            <Box mx={1}>
                                <Link
                                    component={RouterLink}
                                    to="/"
                                    color="inherit"
                                    underline="always"
                                >
                                    <Typography variant="body1">
                                        Home
                                    </Typography>
                                </Link>
                            </Box>
                            <Box mx={1}>
                                <Link
                                    component={RouterLink}
                                    to="/alco-app"
                                    color="inherit"
                                    underline="always"
                                >
                                    <Typography variant="body1">
                                        Alcofunk X BEлодрочЕRы
                                    </Typography>
                                </Link>
                            </Box>
                        </React.Fragment>
                    )}
                    <Box mr={1} ml="auto">
                        <Logo className={classes.logo} />
                    </Box>
                </Toolbar>
            </AppBar>
            <NavDrawer toggleDrawer={toggleDrawer} open={state.open} />
        </div>
    )
}

export default NavBar
