import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.spacing(100),
    },
}))
export default function Alco() {
    const classes = useStyles()

    return (
        <Box mt={2} mx="auto" className={classes.root}>
            <Typography variant="h6">Alcofunk X BEлодрочЕRы</Typography>
        </Box>
    )
}
