import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as RouterLink,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

import Pages from './pages'
import NavBar from './components/nav'
import theme from './theme'
import { makeStyles } from '@material-ui/core/styles'
import NoSsr from '@material-ui/core/NoSsr'

const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
})

function App() {
    const classes = useStyles()
    console.log(theme)
    return (
        <NoSsr>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <NavBar />
                    <Container
                        // disableGutters={true}
                        maxWidth="xl"
                        // color="default"
                        className={classes.root}
                    >
                        <Pages />
                    </Container>
                </Router>
            </ThemeProvider>
        </NoSsr>
    )
}

export default App
