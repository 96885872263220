import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import CallToAction from '@material-ui/icons/CallToAction'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

export default function NavDrawer({ open, toggleDrawer }) {
    const classes = useStyles()
    console.log(open, toggleDrawer)
    return (
        <div>
            <React.Fragment>
                <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                    <div
                        className={classes.list}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText secondary="Home" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button component={Link} to="/alco-app">
                                <ListItemIcon>
                                    <CallToAction />
                                </ListItemIcon>
                                <ListItemText secondary="Alcofunk X BEлодрочЕRы" />
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    )
}
